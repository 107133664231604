import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/event/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        slug: data.slug,
        title: data.title,
        content: data.content,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`event/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    slug: result.slug,
    title: result.title,
    content: result.content,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    slug: input.slug,
    title: input.title,
    content: input.content,
  };

  const response = await axios.post(`event/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    slug: input.slug,
    title: input.title,
    content: input.content,
  };

  const response = await axios.post(`event/update/${id}`, data);

  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`event/delete/${id}`);
  return response.data.success;
};

const guestList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || -1,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/eventGuest/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        event: data.event_id_info ? data.event_id_info : null,
        name: data.name,
        phoneNumber: data.phone_number,
        identity: data.identity,
        code: data.code,
        status: data.status,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const guestDetails = async id => {
  const response = await axios.get(`eventGuest/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    eventId: result.event_id,
    name: result.name,
    phoneNumber: result.phone_number,
    identity: result.identity,
    code: result.code,
    status: result.status,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const guestCreate = async input => {
  const data = {
    event_id: input.eventId,
    name: input.name,
    phone_number: input.phoneNumber,
    identity: input.identity,
    code: input.code,
    status: input.status,
  };

  const response = await axios.post(`eventGuest/create`, data);

  return response.data.result.insert_id;
};


const guestUpdate = async (id, input) => {
  const data = {
    event_id: input.eventId,
    name: input.name,
    phone_number: input.phoneNumber,
    identity: input.identity,
    code: input.code,
    status: input.status,
  };

  const response = await axios.post(`eventGuest/update/${id}`, data);

  return response.data.success;
};

const guestDeleteData = async id => {
  const response = await axios.post(`eventGuest/delete/${id}`);
  return response.data.success;
};

export const EventApi = {
  list,
  details,
  create,
  update,
  deleteData,
  guestList,
  guestDetails,
  guestCreate,
  guestUpdate,
  guestDeleteData,
};
