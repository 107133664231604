import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/member/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        photo: data.photo,
        firstName: data.first_name,
        lastName: data.last_name,
        title: data.title,
        customerNum: data.customer_num,
        areaCode: data.area_code,
        phoneNumber: data.phone_number,
        category: data.category_id_info ? data.category_id_info.name : "",
        updatedAt: data.updated_at,
        createdAt: data.created_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
        createdBy: data.created_by_info ? data.created_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`member/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    photo: result.photo,
    firstName: result.first_name,
    lastName: result.last_name,
    title: result.title,
    categoryId: result.category_id,
    customerNum: result.customer_num,
    areaCode: result.area_code,
    phoneNumber: result.phone_number,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

const create = async input => {
  const formData = new FormData();
  const data = {
    photo: input.photo,
    first_name: input.firstName,
    last_name: input.lastName,
    title: input.title,
    category_id: input.categoryId,
    customer_num: input.customerNum,
    area_code: input.areaCode,
    phone_number: input.phoneNumber,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post("/member/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const formData = new FormData();
  const data = {
    photo: input.photo,
    first_name: input.firstName,
    last_name: input.lastName,
    title: input.title,
    category_id: input.categoryId,
    customer_num: input.customerNum,
    area_code: input.areaCode,
    phone_number: input.phoneNumber,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`member/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`member/delete/${id}`);
  return response.data.success;
};

export const MemberApi = {
  list,
  details,
  create,
  update,
  deleteData,
};
