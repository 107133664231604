import axios from "../helper/AxiosHelper";

export const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "position.ASC",
  };
  const response = await axios.get(`/banner/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        title: data.title,
        position: data.position,
        link: data.link,
        media: data.media,
        alt: data.alt,
        type: data.type,
        isActive: data.is_active,
        updatedAt: data.updated_at,
        createdAt: data.created_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
        createdBy: data.created_by_info ? data.created_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const details = async id => {
  const response = await axios.get(`banner/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    title: result.title,
    position: result.position,
    link: result.link,
    media: result.media,
    alt: result.alt,
    type: result.type,
    isActive: result.is_active,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

export const create = async input => {
  const formData = new FormData();

  const data = {
    title: input.banner.title,
    link: input.banner.link,
    alt: input.banner.alt,
    media: input.banner.media ?? undefined,
    type: input.banner.type,
  };

  formData.append("banner", JSON.stringify(data));

  if (input.banner.mediaFile) {
    formData.append("file", input.banner.mediaFile);
  }

  const response = await axios.post(`banner/createBanner`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result;
};

export const update = async (id, input) => {
  const formData = new FormData();

  const data = {
    title: input.banner.title,
    link: input.banner.link,
    alt: input.banner.alt,
    media: input.banner.media ?? undefined,
    type: input.banner.type,
  };

  formData.append("banner", JSON.stringify(data));

  if (input.banner.mediaFile) {
    formData.append("file", input.banner.mediaFile);
  }

  const response = await axios.post(`banner/updateBanner/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
};

export const batchUpdatePositionAndAvailability = async input => {
  const data = input.map((media, index) => {
    return {
      id: media.id,
      position: index,
      is_active: media.isActive,
    };
  });

  const response = await axios.post(`banner/batchUpdatePositionAndAvailability`, data);
  const success = response.data.success;
  return success;
};

export const deleteData = async id => {
  const response = await axios.post(`banner/delete/${id}`);
  return response.data.success;
};

export const BannerApi = {
  list,
  details,
  create,
  update,
  deleteData,
  batchUpdatePositionAndAvailability,
};
