import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/event/BaseInfo";

// api
import { EventApi } from "../../api/EventApi";

// validation schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required(
    <Translation>{t => t("error.title_is_required")}</Translation>,
  ),
});

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      title: null,
      slug: uuidv4(),
      content: null,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);


  const onSubmit = async () => {
    formik.setTouched({
      title: true,
    });

    formik.validateForm();

    if (!formik.isValid) {
      return;
    }

    const event = {
      ...formik.values,
    };

    try {
      setFullScreenLoading(true);
      const id = await EventApi.create(event);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate("/event");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/event"}>{t("menu.event")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo formik={formik} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
