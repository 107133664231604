import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import DebugLogger from "./DebugLogger";
import FullScreenLoading from "./FullScreenLoading";

export default function ReportExportButton({ headers, csvRef, filename, csvData }) {
  const { t } = useTranslation();
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const download = async () => {
    try {
      setFullScreenLoading(true);
      csvRef.current.link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Box>
      <DebugLogger title={filename} data={csvData} hidden={true} />
      <FullScreenLoading open={fullScreenLoading} />
      <Button
        color="inherit"
        variant="outlined"
        onClick={download}
        startIcon={<FileDownloadIcon />}
      >
        {t("layout.export")}
      </Button>
      <CSVLink
        ref={csvRef}
        headers={headers}
        data={csvData}
        filename={filename || "report.csv"}
        target="_blank"
        uFEFF={true}
      />
    </Box>
  );
}
