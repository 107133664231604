import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/banner/BaseInfo";

// api
import { BannerApi } from "../../api/BannerApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: null,
      link: null,
      media: null,
      alt: null,
      type: null,
    },
    validateOnMount: true,
  });

  const onSubmit = async () => {
    try {
      if (formik.values.media === null || formik.values.media.length === 0) {
        toast.error(t("error.media_is_required"));
        return;
      }

      const data = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      let input = {
        banner: data,
      };

      const id = await BannerApi.create(input);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/banner`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/banner"}>{t("menu.banner")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo formik={formik} />
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={onSubmit} />
    </Container>
  );
}
