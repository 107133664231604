import axios from "../helper/AxiosHelper";

const login = async (username, password) => {
  const data = {
    account: username,
    password: password,
  };

  const response = await axios.post("/login", data);
  return response;
};

const logout = async () => {
  const data = {};

  const response = await axios.post("/logout", data);
  return response;
};

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/user/query?query=${JSON.stringify(query)}&fields=all`);

  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        avatarId: data.avatar_id,
        username: data.username,
        nameZh: data.name_zh,
        nameEn: data.name_en,
        rank: data.rank,
        roles: data.roles,
        email: data.email,
        phoneNumber: data.phone_number == 0 ? '' : data.phone_number,
        nfc: data.nfc,
        remark: data.remark,
        updatedAt: data.updated_at,
        createdAt: data.created_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const queryAllusers = async () => {
  const query = {
    filters: [
      // {
      //   field: "role",
      //   value: [1,2,3],
      //   op: "in",
      // },
    ],
    sort: "id.DESC",
  };
  const response = await axios.get(`/user/query?query=${JSON.stringify(query)}&fields=all`);

  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        avatarId: data.avatar_id,
        username: data.username,
        nameZh: data.name_zh,
        nameEn: data.name_en,
        rank: data.rank,
        email: data.email,
        roles: data.roles,
        phoneNumber: data.phone_number == 0 ? '' : data.phone_number,
        nfc: data.nfc,
        remark: data.remark,
        updatedAt: data.updated_at,
        createdAt: data.created_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`user/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    avatarId: result.avatar_id,
    username: result.username,
    nameZh: result.name_zh,
    nameEn: result.name_en,
    rank: result.rank,
    email: result.email,
    phoneNumber: result.phone_number == 0 ? '' : result.phone_number,
    nfc: result.nfc,
    remark: result.remark,
    roles: result.roles,
    permissions: result.permission,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    avatar_id: input.avatarId,
    username: input.username,
    name_zh: input.nameZh,
    name_en: input.nameEn,
    rank: input.rank,
    email: input.email,
    phone_number: input.phoneNumber,
    roles: input.roles ? input.roles.map(role => role.value) : [],
    nfc: input.nfc,
    remark: input.remark,
    password: input.password,
  };

  const response = await axios.post(`user/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    avatar_id: input.avatarId,
    username: input.username,
    name_zh: input.nameZh,
    name_en: input.nameEn,
    rank: input.rank,
    email: input.email,
    phone_number: input.phoneNumber,
    roles: input.roles ? input.roles.map(role => role.value) : [],
    nfc: input.nfc,
    remark: input.remark,
    password: input.password != "" ? input.password : undefined,
  };

  const response = await axios.post(`user/update/${id}`, data);

  return response.data.success;
};

export const deleteData = async id => {
  const response = await axios.post(`user/delete/${id}`);
  return response.data.success;
};

export const UserApi = {
  login,
  logout,
  list,
  details,
  create,
  update,
  deleteData,
  queryAllusers,
};
