import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,    
    sort: "id.DESC",
  };
  const response = await axios.get(`/role/query?query=${JSON.stringify(query)}&fields=all`);

  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        name: data.name,
        type: data.type,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`role/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    type: result.type,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    name: input.name,
    type: input.type,
  };

  const response = await axios.post(`role/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, role) => {
  const data = {
    name: input.name,
    updated_by: input.updatedBy,
  };

  const response = await axios.post(`role/update/${id}`, data);

  return response.data.success;
};

export const deleteData = async id => {
  const response = await axios.post(`role/delete/${id}`);
  return response.data.success;
};

export const RoleApi = {
  list,
  details,
  create,
  update,
  deleteData,
};
