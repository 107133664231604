import {  Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DebugLogger from "./DebugLogger";

export default function UpdaterInfo({ data }) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={2}
    >
      <DebugLogger title="Updater Info" data={data} hidden={true}></DebugLogger>
      {data.createdBy && data.createdAt && (
        <Typography variant="caption">
          {t("layout.created_by")}: {data.createdBy} - {data.createdAt}
        </Typography>
      )}

      {data.updatedBy && data.updatedAt && (
        <Typography variant="caption">
          {t("layout.updated_by")}: {data.updatedBy} - {data.updatedAt}
        </Typography>
      )}
    </Stack>
  );
}
