import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getMemberPageName } from "../helper/GlobalValueHelper";
import Create from "../views/member/Create";
import Edit from "../views/member/Edit";
import List from "../views/member/List";

const MemberRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/members",
      element: (
        <AuthProvider page={getMemberPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/members/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/create",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default MemberRoute;
