import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";

// components
import DebugLogger from "../../components/DebugLogger";
import FullScreenLoading from "../../components/FullScreenLoading";
import ImagePlaceholder from "../../components/ImagePlaceholder";

// api
import { BannerApi } from "../../api/BannerApi";

export default function List() {
  const { t } = useTranslation();
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveEditPermission, setHaveEditPermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(-1);
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const editPermission = checkPermission(currentUser, currentPage, "update");
    setHaveEditPermission(editPermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await BannerApi.list(page, pageSize, filter);
      setData(response.data);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const create = () => {
    navigate(`/banner/create`);
  };

  const onSubmit = async () => {
    const susccess = await BannerApi.batchUpdatePositionAndAvailability(data);
    if (susccess) {
      toast.success(t("layout.updated_successfully"));
      fetchData();
    }
  };

  const getFileType = type => {
    if (type) {
      return type.split("/")[0].charAt(0).toUpperCase() + type.split("/")[0].slice(1);
    }

    return false;
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        pb: 3,
      }}
    >
      <FullScreenLoading open={fullScreenLoading} />
      <Card variant="outlined">
        <CardHeader
          title={t("menu.banner")}
          action={
            haveCreatePermission && (
              <IconButton onClick={create}>
                <AddIcon />
              </IconButton>
            )
          }
          subheader={
            <Typography variant="caption">
              {t("banner.drag_and_save_to_update_banner_posistion")}
            </Typography>
          }
        />

        <CardContent>
          <DebugLogger title="Banner" data={data} hidden={true}></DebugLogger>
          {data.length !== 0 && (
            <Box sx={{ p: 2 }}>
              <ReactSortable
                list={data}
                setList={value => {
                  setData(value);
                }}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                  gap: 8,
                  alignContent: "flex-start",
                }}
              >
                {data.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      border: "1px solid #e0e0e0",
                      borderRadius: "4px",
                      pt: 4,
                      pb: 4,
                    }}
                  >
                    {file.media && getFileType(file.type) === "Image" && (
                      <ImagePlaceholder
                        url={file.media}
                        styleProps={{
                          width: "180px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    )}

                    {file.media && getFileType(file.type) === "Video" && (
                      <Box
                        component={"video"}
                        src={file.media}
                        controls
                        disablePictureInPicture
                        disableRemotePlayback
                        controlsList="nodownload"
                        sx={{
                          objectFit: "contain",
                          cursor: "pointer",
                          width: "180px",
                          height: "100px",
                        }}
                      // autoPlay  // ** auto play when drag, will cause can't mute bug & server down
                      // muted
                      // loop
                      // onClick={e => {
                      //   e.target.paused ? e.target.play() : e.target.pause();
                      // }}
                      />
                    )}

                    {!file.media && <Box sx={{ width: "100px", height: "100px" }} />}
                    <Box display={"flex"} alignItems={"center"}>
                      <DragIndicatorIcon sx={{ fontSize: 14 }} />

                      <Typography variant="body2" align="center" px={1}>
                        {data[index].title}
                      </Typography>
                      {data.type != "image" && haveEditPermission && (
                        <IconButton
                          onClick={() => {
                            navigate(`/banner/${data[index].id}`);
                          }}
                        >
                          <EditIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                      )}
                    </Box>
                    <Switch
                      checked={file.isActive}
                      onChange={() => {
                        const newBanner = [...data];
                        newBanner[index].isActive = !newBanner[index].isActive;
                        setData(newBanner);
                      }}
                    />
                    <Typography variant="body2" align="center" px={1} mb={1}>
                      {t("layout.file_type")}: {getFileType(data[index].type) || "Unknown"}
                    </Typography>
                  </Box>
                ))}
              </ReactSortable>

              {haveEditPermission && (
                <Box
                  sx={{
                    display: "flex",
                    pt: 3,
                  }}
                  justifyContent={{ xs: "center", md: "end" }}
                >
                  <Button
                    sx={{ py: 1.5, px: 3, m: 1 }}
                    disableElevation
                    variant="contained"
                    onClick={onSubmit}
                  >
                    <Translation>{t => <>{t("layout.save")}</>}</Translation>
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {data.length === 0 && (
            <Typography variant="body2" align="center">
              <Translation>{t => t("layout.no_data")}</Translation>
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
