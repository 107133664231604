import { useRoutes } from "react-router-dom";
import BannerRoute from "./routes/BannerRoute";
import EventRoute from "./routes/EventRoute";
import HomeRoute from "./routes/HomeRoute";
import MemberRoute from "./routes/MemberRoute";
import NotValidRoute from "./routes/NotValidRoute";
import PageNotFoundRoute from "./routes/PageNotFound";
import PermissionModuleRoute from "./routes/PermissionModuleRoute";
import RoleRoute from "./routes/RoleRoute";
import SettingsRoute from "./routes/SettingsRoute";
import UsersRoute from "./routes/UserRoute";

export default function Router() {
  const routes = useRoutes([
    HomeRoute,
    RoleRoute,
    BannerRoute,
    MemberRoute,
    EventRoute,
    NotValidRoute,
    PageNotFoundRoute,
    SettingsRoute,
    UsersRoute,
    PermissionModuleRoute,
  ]);

  return routes;
}
