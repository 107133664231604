import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// helper
import ReactSwal from "../../helper/AlertHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import DebugLogger from "../DebugLogger";
import NFCReaderButton from "../NFCReaderButton";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

// api
import { RoleApi } from "../../api/RoleApi";

export default function BaseInfo({ formik, onDelete, isCreate = false }) {
  const { t } = useTranslation();
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [rolePage, setRolePage] = useState(1);
  const [rolePageSize, setRolePageSize] = useState(-1);
  const [roleFilter, setRoleFilter] = useState([]);

  const userIcons = [
    {
      id: 1,
      url: "/assets/avatar/1.png",
    },
    {
      id: 2,
      url: "/assets/avatar/2.png",
    },
    {
      id: 3,
      url: "/assets/avatar/3.png",
    },
    {
      id: 4,
      url: "/assets/avatar/4.png",
    },
    {
      id: 5,
      url: "/assets/avatar/5.png",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");
    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      const response = await RoleApi.list(rolePage, rolePageSize, roleFilter);
      setOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <CardHeader
        title={t("user.personal_information")}
        subheader={<Typography variant="caption">{t("user.manage_your_profile")}</Typography>}
        action={
          onDelete ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem disabled={!haveDeletePermission} onClick={showDeleteConfirm}>
                  <Typography color={"error"}>{t("layout.delete")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <DebugLogger title={"User"} data={formik.values} hidden={true} />

        <Box sx={{ py: 2 }}>
          <FormLabel required>{t("user.icons")}</FormLabel>
          <RadioGroup
            defaultValue={1}
            value={formik.values.avatarId}
            onChange={event => {
              formik.setFieldValue("avatarId", parseInt(event.target.value));
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              p: 2,
              border: "1px solid #0000001f",
              borderRadius: 1,
              my: 1
            }}
          >
            {userIcons.map((icon, index) => (
              <FormControlLabel
                key={index}
                value={icon.id}
                control={<Radio />}
                label={
                  <Avatar
                    sx={{ border: "1px solid #0000001f" }}
                    alt={`icon-${index}`}
                    src={icon.url}
                  />
                }
              />
            ))}
          </RadioGroup>
        </Box>

        <Grid container spacing={2}>
          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <ValidationTextField
              name={"nameZh"}
              type={"text"}
              required={true}
              value={formik.values.nameZh}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.name_zh")}
              errorText={formik.errors.nameZh}
              touched={formik.touched.nameZh}
            />
          </Grid>

          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <ValidationTextField
              name={"nameEn"}
              type={"text"}
              required={true}
              value={formik.values.nameEn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.name_en")}
              errorText={formik.errors.nameEn}
              touched={formik.touched.nameEn}
            />
          </Grid>

          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <ValidationTextField
              name={"rank"}
              type={"text"}
              required={true}
              value={formik.values.rank}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.rank")}
              errorText={formik.errors.rank}
              touched={formik.touched.rank}
            />
          </Grid>

          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <ValidationTextField
              name={"email"}
              type={"text"}
              // required
              value={formik.values.email || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.email")}
              errorText={formik.errors.email}
              touched={formik.touched.email}
            />
          </Grid>


          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <ValidationTextField
              name={"phoneNumber"}
              // required
              value={formik.values.phoneNumber || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.phone_number")}
              errorText={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
            />
          </Grid>
          
          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <ValidationTextField
              name={"password"}
              type={"password"}
              required={isCreate}
              value={formik.values.password || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("user.password")}
              errorText={formik.errors.password}
              touched={formik.touched.password}
            />
          </Grid>
        </Grid>

        <Stack spacing={2} sx={{
          py: 2
        }}>
          <ValidationTextField
            name={"username"}
            type={"text"}
            required
            value={formik.values.username || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("user.username")}
            errorText={formik.errors.username}
            touched={formik.touched.username}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box flex={1}>
              <ValidationTextField
                name={"nfc"}
                type={"text"}
                value={formik.values.nfc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title={t("user.nfc")}
                errorText={formik.errors.nfc}
                touched={formik.touched.nfc}
              />
            </Box>

            <NFCReaderButton
              onReceive={nfc => {
                formik.setFieldValue("nfc", nfc);
              }}
            />
          </Box>


          <Autocomplete
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => {
              return opt.value === value.value;
            }}
            multiple
            value={formik.values.roles || []}
            options={options.map(role => {
              return {
                label: role.name,
                value: role.id,
              };
            })}
            getOptionLabel={opt => opt.label}
            onChange={(event, newValue) => {
              formik.setFieldValue("roles", newValue);
            }}
            renderInput={params => <TextField required {...params} label={t("user.roles")} />}
          />


          <ValidationTextField
            name={"remark"}
            type={"text"}
            multiline={true}
            value={formik.values.remark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("user.remark")}
            errorText={formik.errors.remark}
            touched={formik.touched.remark}
          />

          <UpdaterInfo data={formik.values} />
        </Stack>



      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
