import Container from "@mui/material/Container";
import MetaHeader from "./components/MetaHeader";
import Router from "./router";

function App() {
  return (
    <MetaHeader>
      <Container disableGutters maxWidth={false}>
        <Router></Router>
      </Container>
    </MetaHeader>
  );
}

export default App;
