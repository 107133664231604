import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function SingleSelect({
  options,
  title,
  value,
  onChange,
  disabled = false,
  required = false,
}) {

  return (
    <FormControl fullWidth>
      <InputLabel required={required}>{title}</InputLabel>
      <Select value={value} label={title} onChange={onChange} disabled={disabled}>
        {options.map(item => (
          <MenuItem value={item.value} key={"options_" + item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
