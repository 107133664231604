import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactSwal from "../../helper/AlertHelper";
import { maxUploadFileMb } from "../../helper/GlobalValueHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { resizeFile } from "../../helper/UnitsHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

export default function BaseInfo({ formik, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [mediaType, setMediaType] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  useEffect(() => {
    if (formik.values.type) {
      setMediaType(formik.values.type.split("/")[0]);
    }
  }, [formik?.values?.type]);

  const onFileChange = async e => {
    let file = e.target.files[0];
    const size = file.size;
    const fileUrl = URL.createObjectURL(file);
    const type = file.type;

    const mediaType = type.split("/")[0];

    if (size > 1024 * 1024 * maxUploadFileMb) {
      toast.error(`Image size must be less than ${maxUploadFileMb}MB`);
      return;
    }

    if (mediaType === "image") {
      const resizedImage = await resizeFile({
        file: file,
      });
      if (!resizedImage) {
        toast.error(t("error.failed_to_resize_image"));
        return;
      }
      file = resizedImage;
    }

    formik.setFieldValue("media", fileUrl);
    formik.setFieldValue("mediaFile", file);
    formik.setFieldValue("type", type);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  const onRemoveBanner = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue("title", null);
        formik.setFieldValue("link", null);
        formik.setFieldValue("media", null);
        formik.setFieldValue("alt", null);
        formik.setFieldValue("type", null);
        formik.setFieldValue("mediaFile", undefined);
      }
    });
  };

  return (
    <Box>
      <Card variant="outlined" sx={{ mb: 5 }}>
        <CardHeader
          title={t("banner.banner_general")}
          action={
            onDelete && haveDeletePermission ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={showDeleteConfirm}>
                    {<Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box />
            )
          }
        />
        <CardContent>
          <DebugLogger title="Banner" data={formik.values} hidden={true}></DebugLogger>
          <Box
            sx={{
              mb: 2,
              fontWight: "bold",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="caption">
              {t("banner.maximum_upload_size")}: {maxUploadFileMb} MB
            </Typography>
            <Typography variant="caption">
              {t("banner.accept_file_type")}: JPEG / JPG / PNG / WEBP / MP4
            </Typography>
            <Typography variant="caption">{t("banner.suggest_ratio")}: 1 : 1</Typography>
            <Typography variant="caption">{t("banner.suggest_resolution")}: 1080 P</Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >

            {formik.values.media && (
              <Stack spacing={2} sx={{ py: 2 }}>
                {mediaType === "image" && (
                  <Box component={"img"} src={formik.values.media} maxHeight={200} maxWidth={"100%"} />
                )}

                {mediaType === "video" && (
                  <Box
                    component={"video"}
                    src={formik.values.media}
                    maxHeight={200}
                    maxWidth={"100%"}
                    controls
                  // autoPlay
                  // muted
                  // loop
                  />
                )}

                <Button
                  sx={{
                    p: 2,
                  }}
                  onClick={onRemoveBanner}
                >
                  {t("layout.remove")}
                </Button>
              </Stack>
            )}

            <Stack width={"100%"} spacing={2}>
              {formik.values.media ? (
                <>
                  <ValidationTextField
                    name={"title"}
                    type={"text"}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title={t("banner.title")}
                    errorText={formik.errors.title}
                    touched={formik.touched.title}
                  />

                  <ValidationTextField
                    name={"link"}
                    type={"text"}
                    fullWidth
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title={t("banner.link")}
                    errorText={formik.errors.link}
                    touched={formik.touched.link}
                  />
                  <ValidationTextField
                    name={"alt"}
                    type={"text"}
                    value={formik.values.alt}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title={t("banner.alt")}
                    errorText={formik.errors.alt}
                    touched={formik.touched.alt}
                  />
                </>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{
                    p: 2,
                    mb: 2,
                  }}
                >
                  {t("layout.click_to_upload_video_or_file")}
                  <Box
                    component={"input"}
                    type="file"
                    onChange={onFileChange}
                    sx={{
                      display: "none",
                    }}
                    accept=".mp4,.jpg,.jpeg,.png,.webp"
                  />
                </Button>
              )}
            </Stack>
          </Box>
          <UpdaterInfo data={formik.values} />
        </CardContent>
      </Card>
    </Box>
  );
}
