import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getBannerPageName } from "../helper/GlobalValueHelper";
import Create from "../views/banner/Create";
import Edit from "../views/banner/Edit";
import List from "../views/banner/List";

const BannerRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/banner",
      element: (
        <AuthProvider page={getBannerPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/banner/create",
      element: (
        <AuthProvider page={getBannerPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/banner/:id",
      element: (
        <AuthProvider page={getBannerPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
  ],
};

export default BannerRoute;
