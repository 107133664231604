import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/role/BaseInfo";

// api
import { PermissionApi } from "../../api/PermissionApi";
import { PermissionModuleApi } from "../../api/PermissionModuleApi";
import { RoleApi } from "../../api/RoleApi";

// validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
});

export default function Create() {
  const { t } = useTranslation();
  const [permissionModule, setPermissionModule] = useState([]);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const navigate = useNavigate();
  const [permissionModulePage, setPermissionModulePage] = useState(1);
  const [permissionModulePageSize, setPermissionModulePageSize] = useState(-1);
  const [permissionModuleFilter, setPermissionModuleFilter] = useState([]);

  useEffect(() => {
    fetchPermissionModuleData();
  }, []);

  const fetchPermissionModuleData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await PermissionModuleApi.list(permissionModulePage, permissionModulePageSize, permissionModuleFilter);
      setPermissionModule(response.data);
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const getPermission = () => {
    let permission = {};
    permissionModule.forEach(item => {
      permission[item.module] = item.actions;
    });
    return permission;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "user",
      permission: getPermission(),
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  const onSubmit = async () => {
    try {
      formik.setTouched({
        name: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const user = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const id = await RoleApi.create(user);
      if (id) {
        const success = await PermissionApi.save(id, user.permission);
        if (success) {
          toast.success(t("layout.created_successfully"));
          navigate(`/role`);
        } else {
          toast.error(t("layout.create_failed"));
        }
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/role"}>{t("menu.role")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <BaseInfo formik={formik} permissionConfig={getPermission()} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
