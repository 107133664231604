import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/event/BaseInfo";
import GuestList from "../event/guest/List";

// api
import { EventApi } from "../../api/EventApi";

// validation schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required(
    <Translation>{t => t("error.title_is_required")}</Translation>,
  ),
});

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  let eventId = id;
  const formik = useFormik({
    initialValues: {
      title: null,
      slug: uuidv4(),
      content: null,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);


  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);

  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await EventApi.details(id);
      if (response) {
        formik.setValues(response);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const update = async () => {
    formik.setTouched({
      title: true,
      slug: true,
    });

    formik.validateForm();

    if (!formik.isValid) {
      return;
    }

    const event = {
      ...formik.values,
    };

    try {
      setFullScreenLoading(true);

      const success = await EventApi.update(id, event);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await EventApi.deleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/event");
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/event"}>{t("menu.event")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo formik={formik} onDelete={onDelete} />
        <GuestList
          eventId={eventId}
          haveCreatePermission={haveCreatePermission}
          haveUpdatePermission={haveUpdatePermission}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
