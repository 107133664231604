import { Box, Divider, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Welcome() {
  const { t } = useTranslation();

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            component={"img"}
            src="/assets/icons/logo_long.png"
            sx={{
              width: "30%",
            }}
          />
          <Typography variant="h5">{t("layout.welcome_header")}</Typography>
          <Divider flexItem />
        </Box>



        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
            py: 4
          }}
        >
          <Typography variant="caption" color={"gray"}>
            {t("layout.version")} : {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
