import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/user/BaseInfo";

// api
import { UserApi } from "../../api/UserApi";

// validation schema
const validationSchema = Yup.object().shape({
  avatarId: Yup.number()
    .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
    .test(
      "isPostive?",
      <Translation>{t => t("error.invalid_format")}</Translation>,
      val => val > 0 && val < 7,
    ),
  nameZh: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  nameEn: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  rank: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  email: Yup.string()
    // .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
    .email(<Translation>{t => t("error.invalid_format")}</Translation>),
  username: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  phoneNumber: Yup.string()
    // .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
    .matches(/^[0-9]*$/, {
      excludeEmptyString: true,
      message: <Translation>{t => t("error.invalid_format")}</Translation>,
    })
    .test(
      "minLength",
      <Translation>{t => t("error.phone_min_length")}</Translation>,
      val => val === undefined || val.length < 1 || val.length > 7,
    ),
  // nfc: Yup.string().required(
  //   <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  // ),
  password: Yup.string()
    .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
    .min(8, <Translation>{t => t("error.password_min_length")}</Translation>)
    .matches(/((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, {
      excludeEmptyString: false,
      message: <Translation>{t => t("error.password_letter")}</Translation>,
    }),
});

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      avatarId: 1,
      username: "",
      nameZh: "",
      nameEn: "",
      rank: "",
      email: "",
      phoneNumber: "",
      roles: [],
      nfc: "",
      remark: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      formik.setTouched({
        avatarId: true,
        nameZh: true,
        nameEn: true,
        rank: true,
        // email: true,
        username: true,
        // phoneNumber: true,
        // nfc: true,
        password: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      let hasError = false;
      if (formik.values.password.length == 0) {
        formik.setFieldError(
          "password",
          <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
        );
        hasError = true;
      }

      if (formik.values.roles.length == 0 || !formik.values.roles) {
        toast.error(t("error.please_select_role"));
        hasError = true;
      }

      if (hasError) {
        return;
      }

      if (formik.values.phoneNumber) {
        formik.values.phoneNumber = parseInt(formik.values.phoneNumber);
      }

      const user = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const id = await UserApi.create(user);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/users`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/users"}>{t("menu.users")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <BaseInfo formik={formik} isCreate={true} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
