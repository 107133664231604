import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import ReactSwal from "../../../helper/AlertHelper";
import { guestIdentityOption, guestStatusOption } from "../../../helper/GlobalValueHelper";
import DebugLogger from "../../DebugLogger";
import UpdaterInfo from "../../UpdaterInfo";
import ValidationTextField from "../../ValidationTextField";
// styles

export default function BaseInfo({
  formik,
  guestDeleteData,
  haveDeletePermission,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        guestDeleteData();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={formik.values.id ? t("event.edit_guest") : t("event.new_guest")}
        action={
          guestDeleteData && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <DebugLogger title={"Guest"} data={formik.values} hidden={true} />

          <ValidationTextField
            name={"name"}
            type={"text"}
            required={true}
            value={formik.values.name || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("event.guest_name")}
            errorText={formik.errors.name}
            touched={formik.touched.name}
          />

          <ValidationTextField
            name={"phoneNumber"}
            required
            value={formik.values.phoneNumber || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("event.guest_phone_number")}
            errorText={formik.errors.phoneNumber}
            touched={formik.touched.phoneNumber}
          />

          <FormControl fullWidth>
            <InputLabel required={true}>{t("event.guest_identity")}</InputLabel>
            <Select value={formik.values.identity} label={t("event.guest_identity")} onChange={(event) => {
              formik.setFieldValue("identity", event.target.value);
            }}>
              {guestIdentityOption.map(item => (
                <MenuItem value={item} key={"options_" + item}>
                  {t(`event.guest_identity_${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel required={true}>{t("layout.status")}</InputLabel>
            <Select value={formik.values.status} label={t("layout.status")} onChange={(event) => {
              formik.setFieldValue("status", event.target.value);
            }}>
              {guestStatusOption.map(item => (
                <MenuItem value={item} key={"options_" + item}>
                  {t(`event.guest_status_${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <UpdaterInfo data={formik.values} />
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
