import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getAppSettingPageName, getSettingsPageName } from "../helper/GlobalValueHelper";
import List from "../views/settings/List";

const SettingsRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/settings",
      element: (
        <AuthProvider page={getSettingsPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    }
  ],
};

export default SettingsRoute;
