import CallIcon from "@mui/icons-material/Call";
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkPermission } from "../helper/PermissionHelper";
import { actionName, pageName } from "../reducers/commonSlice";
import { user } from "../reducers/userSlice";

export default function MeetingBtn() {
  const [haveMeetingPermission, setHaveMeetingPermission] = useState(false);
  const currentPage = useSelector(pageName);
  const currentAction = useSelector(actionName);
  const currentUser = useSelector(user);

  useEffect(() => {
    const meetingPermission = checkPermission(currentUser, currentPage, "view");
    setHaveMeetingPermission(meetingPermission);
  }, [currentUser, currentPage, currentAction]);

  const handleClick = () => {
    window.open("/meeting", "_blank");
  };

  return (
    <Box component={"div"}>
      <IconButton onClick={handleClick} color="inherit">
        {haveMeetingPermission && <CallIcon />}
      </IconButton>
    </Box>
  );
}
