import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactSwal from "../../helper/AlertHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

export default function BaseInfo({ formik, onDelete, permissionConfig = {} }) {
  const { t } = useTranslation();
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const isAdmin = formik.values.type === "admin";

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");
    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  const handlePermissionChange = (event, item, key) => {
    formik.setFieldValue("permission", {
      ...formik.values.permission,
      [key]: event.target.checked
        ? formik.values.permission?.[key]
          ? [...formik.values.permission?.[key], item]
          : [item]
        : formik.values.permission?.[key]?.filter(permission => permission !== item),
    });
  };

  return (
    <Card variant="outlined" sx={{ mb: 5 }}>
      <CardHeader
        title={t("role.personal_information")}
        subheader={<Typography variant="caption">{t("role.manage_your_role")}</Typography>}
        action={
          onDelete ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem disabled={!haveDeletePermission || isAdmin} onClick={showDeleteConfirm}>
                  <Typography color={"error"}>{t("layout.delete")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <DebugLogger title={"Role"} data={formik.values} hidden={true} />

          <ValidationTextField
            name={"name"}
            type={"text"}
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("layout.name")}
            errorText={formik.errors.name}
            touched={formik.touched.name}
            disabled={formik.values.type === "admin" && !!formik.values.id}
          />

          <Box border={1} borderColor="grey.400" borderRadius={1} p={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell variant="h6">
                      <Typography variant="h6">{t("permission.permission_setting")}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(permissionConfig).map(key => (
                    <TableRow key={key}>
                      <TableCell>
                        <Typography variant="h7">{t(`permission.${key}`)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {permissionConfig[key] &&
                            permissionConfig[key].map((item, index) => (
                              <Box key={item} ml={index !== 0 ? 2 : 0}>
                                <FormControlLabel
                                  onChange={event => handlePermissionChange(event, item, key)}
                                  checked={
                                    formik.values.permission?.[key]?.includes(item) || false
                                  }
                                  control={<Checkbox disabled={formik.values.type === "admin"} />}
                                  label={t(`permission.${item}`)}
                                />
                              </Box>
                            ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <UpdaterInfo data={formik.values} />
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
