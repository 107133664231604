import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ClassicEditor from "../../ckeditor5/build/ckeditor";

// helper
import ReactSwal from "../../helper/AlertHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import { toast } from "react-toastify";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";

export default function BaseInfo({ formik, onDelete }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  const onCopyLink = () => {
    const fontendUrl = process.env.REACT_APP_WEB_URL;
    const slug = formik.values.slug;
    const url = `${fontendUrl}/events/${slug}`;
    navigator.clipboard.writeText(url);
    toast.success(t("layout.copied"));
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title={formik.values.id ? t("event.edit_event") : t("event.new_event")}
        action={
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
            {formik.values.id && (
              <Button
                variant="contained"
                size="large"
                onClick={onCopyLink}
              >{t("layout.copy")}</Button>
            )}

            {(onDelete && haveDeletePermission) && (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={showDeleteConfirm}>
                    <Typography color={"error"}>{t("layout.delete")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        }
      />

      <CardContent>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <DebugLogger title={"Event"} data={formik.values} hidden={true} />

          <ValidationTextField
            name={"title"}
            type={"text"}
            required={true}
            value={formik.values.title || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("event.title")}
            errorText={formik.errors.title}
            touched={formik.touched.title}
          />

          <Box
            sx={{
              ".ck-editor__editable ": {
                "& table": {
                  width: "600px",
                },
                "& p": {
                  margin: "0",
                },
                minHeight: "50vh",
              },
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={formik.values.content}
              config={{
                toolbar: [
                  "heading",
                  "undo",
                  "redo",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "alignment",
                  "|",
                  "numberedList",
                  "bulletedList",
                ],
              }}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                formik.setFieldValue("content", data);
              }}
            />
          </Box>
          <UpdaterInfo data={formik.values} />
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
