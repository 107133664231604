import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/member/BaseInfo";

// api
import { CategoryApi } from "../../api/CategoryApi";
import { MemberApi } from "../../api/MemberApi";

// validation schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(<Translation>{t => t("error.first_name_is_required")}</Translation>),
  lastName: Yup.string().required(<Translation>{t => t("error.last_name_is_required")}</Translation>),
  title: Yup.string().required(<Translation>{t => t("error.job_title_is_required")}</Translation>),
  phoneNumber: Yup.string()
    .required(<Translation>{t => t("error.phone_number_is_required")}</Translation>)
    .matches(/^[0-9]+$/, <Translation>{t => t("error.phone_number_is_invalid")}</Translation>),
});

export default function Create({ onClose, onCreated }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [catetoryPage, setCatetoryPage] = useState(1);
  const [catetoryPageSize, setCatetoryPageSize] = useState(-1);
  const [catetoryFilter, setCatetoryFilter] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const formik = useFormik({
    initialValues: {
      customerNum: uuidv4(),
      photo: null,
      firstName: null,
      lastName: null,
      title: null,
      categoryId: 1,
      areaCode: "+852",
      phoneNumber: null,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    try {
      setFullScreenLoading(true);
      const response = await CategoryApi.list(catetoryPage, catetoryPageSize, catetoryFilter);

      if (response.data) {
        setCategoryList(response.data);
      }

      setFullScreenLoading(false);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setFullScreenLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      formik.setTouched({
        firstName: true,
        lastName: true,
        title: true,
        phoneNumber: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const member = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const newMember = await MemberApi.create(member);
      setFullScreenLoading(false);

      if (newMember) {
        toast.success(t("layout.created_successfully"));
        navigate("/members");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <BaseInfo
        formik={formik}
        categoryList={categoryList}
      />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
