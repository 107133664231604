import axios from "../helper/AxiosHelper";

const save = async (id, input) => {
  const data = {
    role_id: id,
    permission: input,
  };

  const response = await axios.post(`permission/save`, data);

  return response.data.success;
};

const permissionDetails = async id => {
  const response = await axios.get(`permission/permissionDetails/${id}`);
  const result = response.data.result;

  return result;
};

export const PermissionApi = {
  save,
  permissionDetails,
};
