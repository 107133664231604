import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

// helper
import ReactSwal from "../../helper/AlertHelper";
import { maxUploadFileMb } from "../../helper/GlobalValueHelper";
import { resizeFile } from "../../helper/UnitsHelper";
import { areaCodeOptions } from "../../helper/GlobalValueHelper";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import DebugLogger from "../DebugLogger";
import FullScreenLoading from "../FullScreenLoading";
import ImageUploader from "../ImageUploader";
import UpdaterInfo from "../UpdaterInfo";
import ValidationTextField from "../ValidationTextField";
import SingleSelect from "../SingleSelect";

// api

export default function BaseInfo({ formik, categoryList, onDelete, haveDeletePermission }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [images, setImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    let newImages = [];

    if (formik.values.photo) {
      newImages = [...images, formik.values.photo];
    }

    if (formik.values.uploadFiles) {
      formik.values.uploadFiles.forEach(file => {
        newImages.push(URL.createObjectURL(file));
      });
    }

    setImages(newImages);
  }, [JSON.stringify(formik.values)]);

  useEffect(() => {
    if (!formik.values.photo) {
      setShowImageUploader(true);
      return;
    }
    let imageLength = 1;
    imageLength += formik.values.uploadFiles?.length || 0;

    let variantLength = 1;

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [JSON.stringify(formik.values)]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  const onImageChange = async images => {
    let uploadFiles = formik.values.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${index + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }

        const resizedImage = await resizeFile({
          file: image.file,
        });

        uploadFiles.push(resizedImage);
      }
    }

    formik.setFieldValue("uploadFiles", uploadFiles);
    images = null;
  };

  const onRemoveImage = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue("photo", "");
        formik.setFieldValue("uploadFiles", []);
        setImages([]);
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = formik.values.uploadFiles;
        newUploadFiles.splice(index, 1);
        formik.setFieldValue("uploadFiles", newUploadFiles);
      }
    });
  };

  const onChangeAreaCode = event => {
    formik.setFieldValue("areaCode", event.target.value);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <FullScreenLoading open={fullScreenLoading} />
      <CardHeader
        title={t("layout.member_general")}
        subheader={<Typography variant="caption">{t("member.manage_member_profile")}</Typography>}
        action={
          onDelete &&
          haveDeletePermission && (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  {<Typography color={"error"}>{t("layout.delete")}</Typography>}
                </MenuItem>
              </Menu>
            </Box>
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <DebugLogger title="Members" data={formik.values} hidden={true}></DebugLogger>
          <DebugLogger title="category" data={categoryList} hidden={true}></DebugLogger>

          {formik.values.customerNum && <QRCode value={formik.values.customerNum} />}

          <Typography>{t("member.profile_photo")}</Typography>
          <Box
            sx={{
              fontWight: "bold",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="caption">
              {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
            </Typography>
            <Typography variant="caption">
              {t("page.accept_file_type")}: JPEG / JPG / PNG / WEBP / SVG
            </Typography>
          </Box>

          {formik.values.photo && (
            <Stack>
              <Box
                component={"img"}
                src={formik.values.photo || ""}
                sx={{
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "200px",
                }}
              />
              <Button
                sx={{
                  py: 2,
                }}
                onClick={() => {
                  onRemoveImage();
                }}
              >
                {t("layout.remove")}
              </Button>
            </Stack>
          )}

          {formik.values.uploadFiles &&
            formik.values.uploadFiles.length !== 0 &&
            formik.values.uploadFiles.map((file, index) => (
              <Stack key={index}>
                <Box
                  component={"img"}
                  src={URL.createObjectURL(file)}
                  sx={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "200px",
                  }}
                />
                <Button
                  sx={{
                    py: 2,
                  }}
                  onClick={() => {
                    removeUploadFile(index);
                  }}
                >
                  {t("layout.remove")}
                </Button>
              </Stack>
            ))}
          {showImageUploader && (
            <ImageUploader
              maxNumber={1}
              images={images}
              maxFileSize={1024 * 1024 * maxUploadFileMb}
              onImageChange={onImageChange}
              onRemoveImage={onRemoveImage}
              removeAllButton={false}
            />
          )}
        </Stack>

        <Grid
          container
          spacing={2}
          sx={{
            py: 2,
          }}
        >
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <ValidationTextField
              name={"firstName"}
              type={"text"}
              required={true}
              value={formik.values.firstName || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("member.first_name")}
              errorText={formik.errors.firstName}
              touched={formik.touched.firstName}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <ValidationTextField
              name={"lastName"}
              type={"text"}
              required={true}
              value={formik.values.lastName || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("member.last_name")}
              errorText={formik.errors.lastName}
              touched={formik.touched.lastName}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <SingleSelect
              options={areaCodeOptions}
              title={t("member.area_code")}
              value={formik.values.areaCode}
              onChange={onChangeAreaCode}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <ValidationTextField
              name={"phoneNumber"}
              type={"text"}
              required={true}
              value={formik.values.phoneNumber || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title={t("member.phone_number")}
              errorText={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
            />
          </Grid>
        </Grid>

        <Stack spacing={2}>
          <ValidationTextField
            name={"title"}
            type={"text"}
            required={true}
            value={formik.values.title || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("member.title")}
            errorText={formik.errors.title}
            touched={formik.touched.title}
          />

          <FormControl fullWidth>
            <InputLabel required={true}>{t("member.category")}</InputLabel>
            <Select
              value={formik.values.categoryId}
              label={t("member.category")}
              onChange={event => {
                formik.setFieldValue("categoryId", event.target.value);
              }}
            >
              {categoryList.map(item => (
                <MenuItem value={item.id} key={"options_" + item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <UpdaterInfo data={formik.values} />
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
